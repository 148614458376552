import HomePage from '../pages/HomePage';
import ConditionsPage from '../pages/ConditionsPage';
import PromotionsPage from '../pages/PromotionsPage';
import AccountsPage from '../pages/AccountsPage';
import FaqPage from '../pages/FaqPage';
import AboutPage from '../pages/AboutPage';
import QuaterlyPage from '../pages/QuaterlyPage';
import IpoPage from '../pages/IpoPage';
import EducationPage from '../pages/EducationPage';

export const routes = [
    { path: '/:lang/', element: HomePage },
    { path: '/:lang/trading-conditions', element: ConditionsPage },
    { path: '/:lang/promotions', element: PromotionsPage },
    { path: '/:lang/account-types', element: AccountsPage },
    { path: '/:lang/faq', element: FaqPage },
    { path: '/:lang/about', element: AboutPage },
    { path: '/:lang/quarterly-reports', element: QuaterlyPage },
    { path: '/:lang/ipo', element: IpoPage },
    { path: '/:lang/education', element: EducationPage },
];

export const headerRoutes = [
    { id: 1, path: '/trading-conditions', text: 'header.conditions' },
    { id: 2, path: '/promotions', text: 'header.promotions' },
    { id: 3, path: '/account-types', text: 'header.accounts' },
    { id: 4, path: '/faq', text: 'header.faq' },
    { id: 5, path: '/about', text: 'header.about' },
    { 
        text: 'header.for_investors',
        routes: [
            { path: '/quarterly-reports', text: 'header.quarterly_reports' },
            { path: '/ipo', text: 'header.ipo' },
        ],
    },
    { id: 7, path: '/education', text: "education_title" },
];
