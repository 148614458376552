import React from "react";
import classes from './Ready.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/QuaterlyPage/ready.webp';
import Button from "../../GlobalComponents/Button/Button";

const Ready = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-48`}>
                        {t('quarterly.ready.title')}
                    </h2>
                    <p className={`${classes.text} font-20`}>
                        {t('quarterly.ready.text')}
                    </p>
                    <div className={classes.btn}>
                        <Button>
                            {t('btn.start_trading')}
                        </Button>
                    </div>
                </div>
                <img className={classes.image} src={image} alt='' />
            </div>
        </section>
    );
};

export default Ready
