import React, { useState } from "react";
import classes from "./Faqs.module.scss";
import { useTranslation } from "react-i18next";

const Faqs = () => {
  const { t } = useTranslation();

  const [spoilers, setSpoilers] = useState([
    {
      id: 1,
      active: false,
      title: "ipo.faqs.tab_01.subtitle",
      text: "ipo.faqs.tab_01.text",
    },
    {
      id: 2,
      active: false,
      title: "ipo.faqs.tab_02.subtitle",
      text: "ipo.faqs.tab_02.text",
    },
    {
      id: 3,
      active: false,
      title: "ipo.faqs.tab_03.subtitle",
      text: "ipo.faqs.tab_03.text",
    },
    {
      id: 4,
      active: false,
      title: "ipo.faqs.tab_04.subtitle",
      text: "ipo.faqs.tab_04.text",
    },
    {
      id: 5,
      active: false,
      title: "ipo.faqs.tab_05.subtitle",
      text: "ipo.faqs.tab_05.text",
    },
  ]);

  const setActiveSpoiler = (itemId) => {
    setSpoilers(
      spoilers.map((curI) => curI.id === itemId
        ? { ...curI, active: !curI.active }
        : { ...curI, active: false }
      )
    )
  };

  return (
    <div className={`${classes.most} mt`}>
      <div className={classes.mostBody}>
        <div className="container">
          <h2 className={[classes.mostTitle, "font-48"].join(" ")}>
            {t('ipo.faqs.title')}
          </h2>
          {spoilers.map((i) => (
            <div key={i.id} className={classes.mostSpoiler}>
              <p
                className={`${classes.mostSpoilerTitle} ${i.active ? classes.active : ''} font-24`}
                onClick={() => setActiveSpoiler(i.id)}
              >
                {t(i.title)}
              </p>
              <div className={`${classes.mostSpoilerContent} ${i.active ? classes.active : ''} font-20`}>
                {t(i.text)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faqs;
