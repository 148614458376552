import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Information from "../components/EducationPage/Information/Information";
import Schedule from "../components/EducationPage/Schedule/Schedule";
import Speaker from "../components/EducationPage/Speaker/Speaker";
import Videos from "../components/EducationPage/Videos/Videos";
import Webinars from "../components/EducationPage/Webinars/Webinars";
import Footer from "../components/GlobalComponents/Footer/Footer";

const EducationPage = () => {
  const [showContent, setShowContent] = useState(false);

  const referrer = document.referrer;
  const properDomain = process.env.REACT_APP_CABINET_LINK_EDU;

  if (!referrer.includes(properDomain)) {
    return window.location.replace(`https://${properDomain}/login`);
  }
  if (referrer.includes(properDomain) && !showContent) {
    setShowContent(true);
  }

  return (
    showContent &&
    <div className="">
      <Helmet>
        <title>BGC Brokers L.P. · Education</title>
      </Helmet>
      <Header />
      <Videos />
      <Information />
      <Webinars />
      <Schedule />
      <Speaker />
      <Footer />
    </div>
  );
};

export default EducationPage;
