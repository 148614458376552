import React from "react";
import classes from './Reports.module.scss';
import { Trans, useTranslation } from "react-i18next";

const Reports = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-48`}>
                    <Trans>
                        {t('quarterly.reports.title')}
                    </Trans>
                </h2>
                <div className={`${classes.text} font-20`}>
                    <p>
                        {t('quarterly.reports.text_01')}
                    </p>
                    <p>
                        {t('quarterly.reports.text_02')}
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Reports;
