import React from "react";
import classes from "./Button.module.scss";
import { useParams } from "react-router-dom";

const Button = ({ children, login, customLink }) => {
  const { lang } = useParams();

  if (customLink) {
    return (
      <a
        href={customLink}
        className={classes.button}
      >
        <span>
          {children}
        </span>
      </a>
    );
  }

  return (
    <a
      href={`${process.env.REACT_APP_CABINET_URL}/${login ? 'login' : 'signup'}?lang=${lang}`}
      className={classes.button}
    >
      <span>
        {children}
      </span>
    </a>
  );
};

export default Button;
