import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Five from "../components/TradingComponents/Five/Five";
import AccountTypes from "../components/AccountsPage/AccountTypes/AccountTypes";

const AccountsPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>BGC Brokers L.P. · Account Types</title>
        <meta name="title" content="BGC Brokers L.P. - Trading Account Types for Every Trader" />
        <meta property="og:title" content="BGC Brokers L.P. - Trading Account Types for Every Trader" />
        <meta property="twitter:title" content="BGC Brokers L.P. - Trading Account Types for Every Trader" />

        <meta name="description" content="Choose from a variety of trading account types that suit your trading style and experience at BGC Brokers L.P.. Start your trading journey today and enjoy low spreads, leverage, and fast execution." />
        <meta property="og:description" content="Choose from a variety of trading account types that suit your trading style and experience at BGC Brokers L.P.. Start your trading journey today and enjoy low spreads, leverage, and fast execution." />
        <meta property="twitter:description" content="Choose from a variety of trading account types that suit your trading style and experience at BGC Brokers L.P.. Start your trading journey today and enjoy low spreads, leverage, and fast execution." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <AccountTypes />
        <Five />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default AccountsPage;
