import React from "react";
import classes from './Docs.module.scss';
import { useTranslation } from "react-i18next";
import icon from '../../../assets/img/AboutPage/pdf_icon.svg';

const docs = [
    {
        link: '/docs/Order_Execution_Policy.pdf',
        text: 'about.docs.Order_Execution_Policy',
    },
    {
        link: '/docs/Managing_Conflicts_of_Interest_Policy.pdf',
        text: 'about.docs.Managing_Conflicts_of_Interest_Policy',
    },
    {
        link: '/docs/Website_Terms_and_Conditions.pdf',
        text: 'about.docs.Website_Terms_and_Conditions',
    },
    {
        link: '/docs/Client_Money_Rules.pdf',
        text: 'about.docs.Client_Money_Rules',
    },
    {
        link: '/docs/Risk_Disclosure.pdf',
        text: 'about.docs.Risk_Disclosure',
    },
    {
        link: '/docs/Execution_Risks.pdf',
        text: 'about.docs.Execution_Risks',
    },
    {
        link: '/docs/Key_Information_Document.pdf',
        text: 'about.docs.Key_Information_Document',
    },
    {
        link: '/docs/Rate_Card.pdf',
        text: 'about.docs.Rate_Card',
    },
];

const Docs = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.banner}>
                <h1 className={`${classes.title} font-48`}>
                    {t('about.docs.title')}
                </h1>
                <p className={`${classes.text} font-20`}>
                    {t('about.docs.text')}
                </p>
                <div className={classes.docs}>
                    {docs.map((doc, index) =>
                        <a
                            key={index}
                            href={doc.link}
                            target="_blank"
                            className={classes.doc}
                        >
                            <img className={classes.docIcon} src={icon} alt='' />
                            <p className={classes.docText}>
                                {t(doc.text)}
                            </p>
                        </a>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Docs;
