import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from "../components/QuaterlyPage/Banner/Banner";
import Reports from "../components/QuaterlyPage/Reports/Reports";
import Ready from "../components/QuaterlyPage/Ready/Ready";

const QuaterlyPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>BGC Brokers L.P.</title>
        <meta name="title" content="Online Trading Platform for Stocks, Cryptocurrencies & More" />
        <meta property="og:title" content="Online Trading Platform for Stocks, Cryptocurrencies & More" />
        <meta property="twitter:title" content="Online Trading Platform for Stocks, Cryptocurrencies & More" />

        <meta name="description" content="BGC Brokers L.P. offers an online trading platform for trading stocks, cryptocurrencies, forex, and more. Get access to the global markets and start trading with a reliable and secure broker. Join now and enjoy commission-free trading!" />
        <meta property="og:description" content="BGC Brokers L.P. offers an online trading platform for trading stocks, cryptocurrencies, forex, and more. Get access to the global markets and start trading with a reliable and secure broker. Join now and enjoy commission-free trading!" />
        <meta property="twitter:description" content="BGC Brokers L.P. offers an online trading platform for trading stocks, cryptocurrencies, forex, and more. Get access to the global markets and start trading with a reliable and secure broker. Join now and enjoy commission-free trading!" />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <Reports />
        <Ready />        
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default QuaterlyPage;
