import React from "react";
import classes from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image from '../../../assets/img/QuaterlyPage/banner.webp';

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h1 className={`${classes.title} font-64`}>
                        <Trans>
                            {t('quarterly.banner.title')}
                        </Trans>
                    </h1>
                    <p className={`${classes.text} font-20`}>
                        {t('quarterly.banner.text')}
                    </p>
                </div>
                <img className={classes.image} src={image} alt='' />
            </div>
        </section>
    );
};

export default Banner;
