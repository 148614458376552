import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from "../components/AboutPage/Banner/Banner";
import OurValues from "../components/AboutPage/OurValues/OurValues";
import Five from "../components/TradingComponents/Five/Five";
import Docs from '../components/AboutPage/Docs/Docs';

const AboutPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>BGC Brokers L.P. · About Us</title>
        <meta name="title" content="About BGC Brokers L.P. | The Leading Trading Platform" />
        <meta property="og:title" content="About BGC Brokers L.P. | The Leading Trading Platform" />
        <meta property="twitter:title" content="About BGC Brokers L.P. | The Leading Trading Platform" />

        <meta name="description" content="Learn more about BGC Brokers L.P., the premier trading platform offering diverse investment options in Forex, stocks, cryptocurrencies, and more.Explore our company's values, and mission, and how we strive to provide our clients with the best trading conditions and exceptional customer service. Join us now and start your trading journey today!" />
        <meta property="og:description" content="Learn more about BGC Brokers L.P., the premier trading platform offering diverse investment options in Forex, stocks, cryptocurrencies, and more.Explore our company's values, and mission, and how we strive to provide our clients with the best trading conditions and exceptional customer service. Join us now and start your trading journey today!" />
        <meta property="twitter:description" content="Learn more about BGC Brokers L.P., the premier trading platform offering diverse investment options in Forex, stocks, cryptocurrencies, and more.Explore our company's values, and mission, and how we strive to provide our clients with the best trading conditions and exceptional customer service. Join us now and start your trading journey today!" />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <Docs />
        <OurValues />
        <Five />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default AboutPage;
