import React from "react";
import classes from './Steps.module.scss';
import { useTranslation } from "react-i18next";

const cards = [
    {
        subtitle: 'ipo.steps.card_01.subtitle',
        desc: 'ipo.steps.card_01.desc',
    },
    {
        subtitle: 'ipo.steps.card_02.subtitle',
        desc: 'ipo.steps.card_02.desc',
    },
    {
        subtitle: 'ipo.steps.card_03.subtitle',
        desc: 'ipo.steps.card_03.desc',
    },
];

const Steps = () => {
    const { t } = useTranslation();
    return (
        <section className="mt">
            <div className={classes.wrap}>
                <div className="container">
                    <div className={classes.body}>
                        <h2 className={`${classes.title} font-48`}>
                            {t('ipo.steps.title')}
                        </h2>
                        <div className={classes.cards}>
                            {cards.map((card, index) =>
                                <div key={index} className={classes.card}>
                                    <div className={classes.num}>
                                        {index + 1}
                                    </div>
                                    <h4 className={`${classes.subtitle} font-20`}>
                                        {t(card.subtitle)}
                                    </h4>
                                    <p className={classes.desc}>
                                        {t(card.desc)}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <p className={classes.text}>
                    {t('ipo.steps.text')}
                </p>
            </div>
        </section>
    );
};

export default Steps;
