import React from "react";
import classes from './Access.module.scss';
import { Trans, useTranslation } from "react-i18next";
import mark from '../../../assets/img/IpoPage/mark.svg';
import image from '../../../assets/img/IpoPage/access.png';

const list = [
    {
        subtitle: 'ipo.access.li_01.subtitle',
        desc: 'ipo.access.li_01.desc',
    },
    {
        subtitle: 'ipo.access.li_02.subtitle',
        desc: 'ipo.access.li_02.desc',
    },
];

const Access = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-48`}>
                        <Trans>
                            {t('ipo.access.title')}
                        </Trans>
                    </h2>
                    <p className={`${classes.text_01} font-20`}>
                        {t('ipo.access.text_01')}
                    </p>
                    <ul className={`${classes.list} font-20`}>
                        {list.map((li, index) =>
                            <li key={index} className={classes.li}>
                                <img className={classes.mark} src={mark} alt='' />
                                <div className={classes.content}>
                                    <h4 className={classes.subtitle}>
                                        {t(li.subtitle)}
                                    </h4>
                                    <p className={classes.text}>
                                        {t(li.desc)}
                                    </p>
                                </div>
                            </li>
                        )}
                    </ul>
                    <p className={`${classes.text_02} font-20`}>
                        {t('ipo.access.text_02')}
                    </p>
                </div>
                <img className={classes.image} src={image} alt='' />
            </div>
        </section>
    );
};

export default Access;
